<template>
  <Details
    title="lease"
    resource="leases"
    :base-path="basePath"
    data-provider="$raaDataProvider"
    :title-template-render="renderTitle"
    :breadcrumb-template-render="renderBreadcrumbs"
  >
    <template v-slot:side-panel="{ record }">
      <DetailsSidePanel
        entity-title="lease"
        :view-mode="record.source !== authSource || Boolean(record.deletedAt)"
        :source="record.source !== authSource ? record.source : ''">
      </DetailsSidePanel>
    </template>
    <template v-slot:default="detailsProps">
      <div class="flex flex-col items-end justify-end text-sm mb-4">
        <span>source: <span class="text-black font-600 ml-1">{{detailsProps.record.source}}</span></span>
        <span v-if="detailsProps.record.deletedAt">{{detailsProps.getDeletedLabel(detailsProps.record.deletedAt)}}</span>
      </div>
      <LeaseForm
        v-if="detailsProps.record.source === authSource && !detailsProps.record.deletedAt"
        :initialValues="detailsProps.record"
        :on-submit="detailsProps.handleSubmit"
        :loading="detailsProps.loading"
        @close="detailsProps.redirectToList"
      />
      <LeaseDetails v-else :initialValues="detailsProps.record" />
    </template>
  </Details>
</template>

<script>
  import ModalNavigation from "@/mixins/ModalNavigation";
  import Details from "@/components/auth/details/Details";
  import DetailsSidePanel from "@/components/auth/details/DetailsSidePanel";
  import LeaseForm from "@/views/auth/raa-devtools/leases/LeaseForm";
  import LeaseDetails from "@/views/auth/raa-devtools/leases/LeaseDetails";
  import { AUTH_ALIAS } from '@/components/auth/constants';

  export default {
    name: "LeaseEdit",
    components: {LeaseDetails, LeaseForm, Details, DetailsSidePanel},
    mixins: [ModalNavigation],
    computed: {
      basePath: function () {
        return this.$route.name.replace('edit', 'index');
      },
      authSource() {
        return AUTH_ALIAS;
      },
    },
    methods:{
      renderBreadcrumbs({ source, deletedAt }) {
        return source === this.authSource && !deletedAt ? 'edit' : 'view';
      },
      renderTitle({ source, deletedAt }) {
        return `${source === this.authSource && !deletedAt ? 'edit' : 'view'} lease`;
      }
    },
  }
</script>

<style scoped>

</style>
